import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { Container, Row } from "react-awesome-styled-grid"
import siteConfig from "../../data/siteConfig"
import loadable from "@loadable/component"
import Hero from "../components/hero"
import Wrapper from "../components/wrapper"
import { GlobalStateContext } from "../components/context"
import SEO from "../components/SEO"

const Layout = loadable(() => import("../components/layout"))

const JobCardContainer = styled.div`
  object-fit: cover;
  background-image: url(/images/projects-background.jpg);
  background-repeat: repeat;
  background-position: center bottom;
  position: relative;
  height: 150px;
  width: 960px;
  border-radius: 10px;
  box-shadow: 24px 47px 79px -21px rgba(0, 0, 0, 0.51);
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  @media (max-width: 415px) {
    height: 200px;
  }
`

const LinkJobCard = styled(Link)`
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
  &:hover ${JobCardContainer} {
    transition: transform 0.2s;
    transform: translateY(-5px);
  }
  @media (max-width: 415px) {
    margin-bottom: 60px;
  }
`

const StyledWrapper = styled(Wrapper)`
  border: none;
  display: flex;
  flex-direction: column;
  top: -80px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.wrapperBackground};
  color: ${({ theme }) => theme.colors.wrapperTextColor};
  div > div > div * {
    color: ${({ theme }) => theme.colors.wrapperTextColor};
  }
  @media (max-height: 900px) {
    top: -120px;
  }

  @media (max-height: 667px) {
    top: -80px;
  }
`

const ProjectTitle = styled.h1`
  margin-left: 1rem;
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.6);
  -webkit-text-stroke: 1px black;
  @media (max-width: 415px) {
    margin-bottom: 100px;
  }
`

const ProjectDescription = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.projectDescriptionColor};
  color: ${({ theme }) => theme.colors.fontColor};
  height: 3rem;
  position: absolute;
  bottom: 0;
  @media (max-width: 481px) {
    height: 4rem;
  }
  @media (max-width: 415px) {
    height: 8rem;
  }
  @media (max-width: 320px) {
    height: 7rem;
  }
`

const Projetos = ({ data, className, location, theme }) => {
  const state = useContext(GlobalStateContext)
  const projects = data.allMarkdownRemark.edges
  const { keywords } = siteConfig
  const SEOImage = siteConfig.siteProjectsImage
  const SEOtitle = state.language === "ptBR" ? "Projetos" : "Projects"

  return (
    <>
      <SEO
        image={SEOImage}
        title={SEOtitle}
        keywords={keywords}
        pathname={location.pathname}
        article
      />
      <Layout state={state} location={location.pathname} theme={theme}>
        <Hero
          heroImg={siteConfig.siteCover}
          title={state.language === "ptBR" ? "Projetos" : "Projects"}
        />
        <StyledWrapper className={className}>
          <Container className="page-content" fluid>
            {projects.map(
              project =>
                project.node.frontmatter.Language === state.language && (
                  <Row
                    key={project.node.frontmatter.title}
                    align="center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <LinkJobCard to={project.node.frontmatter.path}>
                      <JobCardContainer>
                        <ProjectTitle>
                          {project.node.frontmatter.title}
                        </ProjectTitle>
                        <ProjectDescription theme={theme}>
                          {project.node.frontmatter.description}
                        </ProjectDescription>
                      </JobCardContainer>
                    </LinkJobCard>
                  </Row>
                )
            )}
          </Container>
        </StyledWrapper>
      </Layout>
    </>
  )
}

export default styled(Projetos)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
            Language
          }
        }
      }
    }
  }
`
